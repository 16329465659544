import { combinePath, encodeComponent } from './path';
import { Route } from './Route';
import { Page } from 'types';

/**
 * Creates routes based on pages.
 * @param pages to create routes for.
 * @param parentId filter by parentId.
 * @param basePath from page hierarchy.
 * @returns Routes.
 */
export function createRoutes(pages: Page[], parentId?: string, basePath?: string): Route[] {
  let routes: Route[] = [];

  pages
    .filter((p) => p.type != undefined && p.parentId?.toString() == parentId)
    .forEach((page, index) => {
      const isFirstPage = !basePath && index === 0;
      const path =
        page.url && page.url != ''
          ? combinePath(basePath || '', ...page.url.split('/').map((p) => encodeComponent(p)))
          : combinePath(basePath || '', encodeComponent(page.title));

      routes.push({
        componentName: page.type?.name || '',
        pageId: page.id,
        parentId: page.parentId?.toString(),
        path: isFirstPage ? '/' : path,
        exact: true,
        title: page.browserTitle || page.title,
        linkTitle: page.navigationTitle || page.title,
        meta: {
          description: page.description,
          keywords: page.keywords,
        },
      });

      // Add children recursively
      const children = pages.filter((p) => p.parentId && p.parentId.toString() == page.id);
      if (children.length > 0) {
        const childRoutes = createRoutes(children, page.id, path);
        routes = routes.concat(childRoutes);
      }
    });

  return routes;
}
