import React from 'react';
import { Helmet } from 'react-helmet-async';
import { StringMap } from 'types';

interface Props {
  title?: string;
  metadata?: StringMap;
}

const MetaTags: React.FC<Props> = ({ title, metadata }: Props) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {metadata &&
        Object.entries(metadata).map(([name, content]) => (
          <meta key={name} name={name} content={content?.toString() || ''} />
        ))}
    </Helmet>
  );
};

export default MetaTags;
