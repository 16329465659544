import { Container, Element } from 'types';
import { getDocumentsViewData, DocumentViewData } from './documents';
import { getImagesViewData, ImageViewData } from './images';
import { tryParse } from './tryParse';

export interface ElementViewData<TData = any> {
  componentName: string;
  data?: TData;
  element: Element;
  documents: Array<DocumentViewData>;
  images: Array<ImageViewData>;
}

export interface HeadlineViewData {
  title?: string;
  subtitle?: string;
}

export enum LinkType {
  None = '',
  External = 'external',
  Internal = 'internal',
}

export interface LinkViewData {
  pageId?: string;
  sectionId?: string;
  anchor?: string;
  linkType: LinkType;
  title: string;
  url?: string;
}

export interface MapViewData {
  title?: string;
  center?: Array<number>;
  marker?: Array<number>;
  zoom?: number;
}

/**
 * Returns an array of elements of the provided container that matches the type name.
 * @param container that contains elements.
 * @param type name or an array of type names.
 * @returns An array of elements if successful, otherwise an empty array.
 */
export function findElements<TData = any>(
  container: Container,
  type: string | string[]
): Array<ElementViewData<TData>> {
  const typeArray = Array.isArray(type) ? type : [type];
  return (
    container.elements
      ?.filter((e) => typeArray.includes(e.type?.name || ''))
      .map((e) => getElementViewData(e)) || []
  );
}

/**
 * Returns the first element of the provided container that matches the type name.
 * @param container that contains elements.
 * @param type name.
 * @returns The first element if successful, otherwise undefined.
 */
export function findElement<TData = any>(
  container: Container,
  type: string
): ElementViewData<TData> | undefined {
  const elements = findElements(container, type);
  return elements.length > 0 ? elements[0] : undefined;
}

/**
 * Returns view data for the specified element.
 * @param element to get view data for.
 * @returns Element view data.
 */
export function getElementViewData<TData = any>(element: Element): ElementViewData<TData> {
  const documents = element.documents ? getDocumentsViewData(element.documents) : [];
  const images = element.images ? getImagesViewData(element.images) : [];

  return {
    componentName: element.type?.name || '',
    data:
      element.dataFormat === 'application/json' ? tryParse(element.data) : (element.data as any),
    element,
    documents,
    images,
  };
}

/**
 * Returns an array of view data for the specified elements.
 * @param elements to get view data for.
 * @returns Array of elements view data.
 */
export function getElementsViewData<TData = any>(
  elements: Array<Element>
): Array<ElementViewData<TData>> {
  return elements ? elements.map((e) => getElementViewData(e)).filter((e) => !!e) : [];
}
