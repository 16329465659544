import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
  dark?: boolean;
  open?: boolean;
  onToggle?: () => void;
}

const MenuToggle: React.FC<Props> = ({ className, dark, open, onToggle }: Props) => {
  return (
    <div className={clsx('cursor-pointer', className)} onClick={onToggle}>
      {!open ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
          className={clsx(
            'w-5 sm:w-6 md:w-7 lg:w-8 stroke-current hover:text-ci-orange',
            dark ? 'text-ci-gray' : 'text-white'
          )}
        >
          <path d="M0 10 H36 M0 18 H36 M0 26 H36" strokeWidth="3" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
          className={clsx(
            'w-5 sm:w-6 md:w-7 lg:w-8 stroke-current hover:text-ci-orange',
            dark ? 'text-ci-gray' : 'text-white'
          )}
        >
          <path d="M5 5 L31 31 M31 5 L5 31" strokeWidth="3" />
        </svg>
      )}
    </div>
  );
};

export default MenuToggle;
