import clsx from 'clsx';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const MaxWidth: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div className={clsx('max-w-[75rem] mx-auto px-4 sm:px-6 lg:px-8', className)}>{children}</div>
  );
};

export default MaxWidth;
