import React from 'react';
import Link from 'components/Link';
import { MenuItemViewData } from 'lib/menu';
import { LinkType } from 'lib/elements';

interface Props {
  classNames?: Array<string>;
  activeClassNames?: Array<string>;
  itemClassNames?: Array<string>;
  items: Array<MenuItemViewData>;
  level?: number;
}

const Menu: React.FC<Props> = ({
  classNames,
  activeClassNames,
  itemClassNames,
  items,
  level = 0,
}: Props) => {
  if (items.length === 0) {
    return null;
  }

  // Get menu and item class names based on menu level
  const className =
    classNames && classNames.length > 0
      ? level || 0 < classNames.length
        ? classNames[level || 0]
        : classNames[classNames.length - 1]
      : undefined;
  const itemClassName =
    itemClassNames && itemClassNames.length > 0
      ? level || 0 < itemClassNames.length
        ? itemClassNames[level || 0]
        : itemClassNames[itemClassNames.length - 1]
      : undefined;
  const activeClassName =
    activeClassNames && activeClassNames.length > 0
      ? level || 0 < activeClassNames.length
        ? activeClassNames[level || 0]
        : activeClassNames[activeClassNames.length - 1]
      : undefined;

  return (
    <ul className={className}>
      {items.map((item) => (
        <li key={item.id}>
          {item.linkType !== LinkType.None ? (
            <Link activeClassName={activeClassName} className={itemClassName} {...item}>
              {item.title}
            </Link>
          ) : (
            <span className={itemClassName}>&nbsp;</span>
          )}
          {item.children && (
            <Menu
              classNames={classNames}
              activeClassNames={activeClassNames}
              itemClassNames={itemClassNames}
              items={item.children}
              level={level + 1}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default Menu;
