import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SiteContext } from 'contexts/SiteContext';

const FooterMenu: React.FC = () => {
  const { routes } = useContext(SiteContext);
  const filteredRoutes = routes.filter((r) => !r.parentId);

  // Split filtered main routes in the middle, one half for the left and the rest for the right
  const splitIndex = filteredRoutes.length > 1 ? Math.ceil(filteredRoutes.length / 2) : 0;
  const leftRoutes = splitIndex > 0 ? filteredRoutes.slice(0, splitIndex) : filteredRoutes;
  const rightRoutes = splitIndex > 0 ? filteredRoutes.slice(splitIndex) : [];

  return (
    <>
      <ul className="text-ci-blue-gray leading-loose">
        {leftRoutes
          .filter((r) => !r.parentId)
          .map((route) => (
            <li key={route.pageId}>
              <Link
                to={route.path}
                className="block opacity-50 whitespace-nowrap overflow-ellipsis overflow-hidden hover:opacity-100 hover:text-ci-orange lg:py-2"
              >
                {route.linkTitle || route.title}
              </Link>
            </li>
          ))}
      </ul>
      <ul className="text-right text-ci-blue-gray leading-loose">
        {rightRoutes
          .filter((r) => !r.parentId)
          .map((route) => (
            <li key={route.pageId}>
              <Link
                to={route.path}
                className="block opacity-50 whitespace-nowrap overflow-ellipsis overflow-hidden hover:opacity-100 hover:text-ci-orange lg:py-2"
              >
                {route.linkTitle || route.title}
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
};

export default FooterMenu;
