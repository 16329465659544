import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LanguageMenu from 'components/LanguageMenu';
import Logo from 'components/Logo';
import MainMenu from 'components/MainMenu';
import MaxWidth from 'components/MaxWidth';
import MenuToggle from 'components/MenuToggle';
import QuickStartMenu from 'components/QuickStartMenu';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = ({ className }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleMenu = (): void => setOpen((prev) => !prev);
  const closeMenu = (): void => setOpen(false);

  return (
    <header
      className={clsx(
        'fixed top-0 z-30 w-full h-28 transition-top duration-500 ease-in-out shadow-md bg-white',
        className
      )}
    >
      <MaxWidth className="relative">
        <div className={clsx('absolute z-30 top-10 left-0 w-full box-border px-4 sm:px-6 lg:px-8')}>
          <div className="flex justify-between items-center">
            <Link className="block" to="/">
              <Logo className={clsx('text-ci-blue w-14 sm:w-16 md:w-20 lg:w-24')} />
            </Link>
            <div className="flex justify-end items-center">
              <QuickStartMenu open={!open} onClose={closeMenu} />
              <LanguageMenu className="px-4 sm:px-6 lg:px-8" dark />
              <MenuToggle className="justify-self-end" dark open={open} onToggle={toggleMenu} />
            </div>
          </div>
        </div>
      </MaxWidth>
      <MainMenu open={open} onClose={closeMenu} />
    </header>
  );
};

export default Header;
