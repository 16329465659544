import React from 'react';
import { useContent } from 'hooks/useContent';
import { useScrollTo } from 'hooks/useScrollTo';

const Hidden: React.FC = () => {
  const { section } = useContent();
  const { scrollRef } = useScrollTo(section?.anchorName);
  return (
    <section
      /*id={section?.anchorName?.toLocaleLowerCase()}*/ className="hidden"
      ref={scrollRef}
    ></section>
  );
};

export default Hidden;
