import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAppleAlt,
  faAppleCrate,
  faBacon,
  faBone,
  faBreadLoaf,
  faBreadSlice,
  faBurgerSoda,
  faBurrito,
  faCandyCane,
  faCandyCorn,
  faCarrot,
  faCheese,
  faCheeseSwiss,
  faCheeseburger,
  faCloudMeatball,
  faCookie,
  faCorn,
  faCroissant,
  faDrumstick,
  faDrumstickBite,
  faEgg,
  faEggFried,
  faFish,
  faFishCooked,
  faFrenchFries,
  faGingerbreadMan,
  faHamburger,
  faHatChef,
  faHotdog,
  faIceCream,
  faLemon,
  faMeat,
  faPepperHot,
  faPie,
  faPizza,
  faPizzaSlice,
  faPopcorn,
  faPumpkin,
  faSalad,
  faSandwich,
  faSausage,
  faSeedling,
  faShishKebab,
  faSoup,
  faSteak,
  faStroopwafel,
  faTaco,
  faTurkey,
  faWheat,
} from '@fortawesome/pro-regular-svg-icons';
import { StringMap } from 'types';

interface Props {
  icons: string[];
  title: string;
}

const Icons: React.FC<Props> = ({ icons, title }: Props) => {
  const iconMap: StringMap = {
    appleAlt: faAppleAlt,
    appleCrate: faAppleCrate,
    bacon: faBacon,
    bone: faBone,
    breadLoaf: faBreadLoaf,
    breadSlice: faBreadSlice,
    burgerSoda: faBurgerSoda,
    burrito: faBurrito,
    candyCane: faCandyCane,
    candyCorn: faCandyCorn,
    carrot: faCarrot,
    cheese: faCheese,
    cheeseSwiss: faCheeseSwiss,
    cheeseburger: faCheeseburger,
    cloudMeatball: faCloudMeatball,
    cookie: faCookie,
    corn: faCorn,
    croissant: faCroissant,
    drumstick: faDrumstick,
    drumsticekBite: faDrumstickBite,
    egg: faEgg,
    eggFried: faEggFried,
    fish: faFish,
    fishCooked: faFishCooked,
    frenchFries: faFrenchFries,
    gingerbreadMan: faGingerbreadMan,
    hamburger: faHamburger,
    hatChef: faHatChef,
    hotdog: faHotdog,
    iceCream: faIceCream,
    lemon: faLemon,
    meat: faMeat,
    pepperHot: faPepperHot,
    pie: faPie,
    pizza: faPizza,
    pizzaSlice: faPizzaSlice,
    popcorn: faPopcorn,
    pumpkin: faPumpkin,
    salad: faSalad,
    sandwich: faSandwich,
    sausage: faSausage,
    seedling: faSeedling,
    shishKebab: faShishKebab,
    soup: faSoup,
    steak: faSteak,
    stroopwafel: faStroopwafel,
    taco: faTaco,
    turkey: faTurkey,
    wheat: faWheat,
  };

  const mappedIcons = icons.map((icon) => iconMap[icon]).filter((i) => i);

  return (
    <div className="py-4 pt-8">
      <div className="pb-4 font-extrabold text-sm">{title}</div>
      <ul className="flex space-x-4">
        {mappedIcons &&
          mappedIcons.map((icon, index) => (
            <li key={index} className="flex items-center">
              <FontAwesomeIcon icon={icon} className="text-2xl" />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Icons;
