import clsx from 'clsx';
import React from 'react';
import { findElement, HeadlineViewData } from 'lib/elements';
import { tryParse } from 'lib/tryParse';

import { ContainerProps } from './ContainerProps';

interface OptionProps {
  color?: 'blue' | 'orange';
  background?: 'gray' | 'white' | 'transparent';
}

const HeadlineImage: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const headline = findElement<HeadlineViewData>(container, 'Headline');
  const image = findElement(container, 'Image');
  const imageSrc =
    image && image.images && image.images.length > 0 ? image.images[0].src : undefined;

  const props = tryParse<OptionProps>(container.props);
  const color = (props && props.color) || (imageSrc ? 'orange' : 'blue');
  const background = (props && props.background) || (imageSrc ? 'white' : 'gray');
  const twBackground =
    background === 'gray' ? 'bg-gray-50' : background === 'white' ? 'bg-white' : '';

  const style = {
    backgroundImage: imageSrc ? `url('${imageSrc}')` : 'none',
  };

  return (
    <div
      className={clsx(
        'flex justify-center items-center bg-no-repeat bg-center bg-cover h-64 lg:h-[500px]',
        twBackground,
        className
      )}
      style={style}
      data-component="HeadlineImage"
    >
      {headline && (
        <div>
          <h3
            className={clsx(
              'font-handwritten text-4xl font-extrabold tracking-normal whitespace-pre-line text-center sm:text-6xl lg:text-8xl',
              color === 'blue' ? 'text-ci-blue-light' : 'text-ci-orange'
            )}
          >
            {headline.data?.title}
          </h3>
          {headline.data?.subtitle && (
            <span
              className={clsx(
                'block -bottom-8 left-0 font-text text-4xs tracking-3widest font-extrabold uppercase text-center',
                color === 'blue' ? 'text-gray' : 'text-white'
              )}
            >
              {headline.data.subtitle}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default HeadlineImage;
