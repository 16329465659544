import React from 'react';
import { CatalogComponent } from 'react-component-catalog';
import { ContainerProvider } from 'contexts/ContainerContext';
import { useContent } from 'hooks/useContent';
import Default from './Default';

import EmployeeCard from './EmployeeCard';
import Form from './Form';
import GoogleMap from './GoogleMap';
import Headline from './Headline';
import HeadlineImage from './HeadlineImage';
import Hero from './Hero';
// import InfoGraphics from './InfoGraphics';
import ProductCard from './ProductCard';
import TextColumns from './TextColumns';
import TextImage from './TextImage';
import TextMap from './TextMap';

export const catalog = {
  EmployeeCard,
  Form,
  GoogleMap,
  Headline,
  HeadlineImage,
  Hero,
  // InfoGraphics,
  ProductCard,
  TextColumns,
  TextImage,
  TextMap,
};

interface Props {
  sectionId: string;
}

const Containers: React.FC<Props> = ({ sectionId }: Props) => {
  const { getContainers } = useContent();

  // TODO: Implement default container as fallback
  return (
    <>
      {getContainers(sectionId).map((container) => (
        <ContainerProvider key={container.id} containerId={container.id}>
          <CatalogComponent
            component={`containers.${container.type?.name}`}
            fallbackComponent={Default}
            /* Other props */
            container={container}
            componentName={container.type?.name}
          />
        </ContainerProvider>
      ))}
    </>
  );
};

export default Containers;
