import { LinkType, LinkViewData } from './elements';
import { getRouteTo, Route } from 'routes';
import { MenuItem } from 'types';

export interface MenuItemViewData extends LinkViewData {
  id: string;
  children?: Array<MenuItemViewData>;
  to?: string;
}

/**
 * Generates menu items view data based on a flat array of items.
 * @param items flat array of all menu items.
 * @param parentId optional to filter items of a specific parent.
 * @param level of menu items zero based.
 * @returns hierarchical view data of menu items.
 */
export function getMenuItemsViewData(
  items: Array<MenuItem>,
  routes: Route[] = [],
  parentId?: string,
  level = 0,
  maxLevel = 2
): Array<MenuItemViewData> | undefined {
  if (level > maxLevel) {
    return undefined;
  }

  const levelItems =
    items?.filter((i) =>
      parentId ? i.parentId && i.parentId.toString() === parentId : !i.parentId
    ) || [];

  if (levelItems.length === 0) {
    return undefined;
  }

  return levelItems.map((item) => ({
    id: item.id,
    pageId: item.pageId?.toString(),
    sectionId: item.sectionId?.toString(),
    anchor: item.anchor,
    linkType: item.pageId ? LinkType.Internal : item.url ? LinkType.External : LinkType.None,
    title: item.title || '',
    to: item.pageId ? getRouteTo(routes, item.pageId.toString(), item.anchor) : undefined,
    url: item.url,
    children: getMenuItemsViewData(items, routes, item.id, level + 1, maxLevel),
  }));

  // const filteredItems: Array<MenuItemViewData> = items
  //   ? (items
  //       .filter((i) =>
  //         parentId && i.parentId ? i.parentId.toString() === parentId : !i.parentId && !parentId
  //       )
  //       .map((item) => {
  //         const hasChildren =
  //           items.filter((i) => i.parentId && i.parentId.toString() === item.id).length > 0;
  //         if (item.pageId) {
  //           const route = routes.find((r) => item.pageId && item.pageId.toString() === r.pageId);
  //           return route
  //             ? {
  //                 id: item.id,
  //                 title: item.title || route.linkTitle,
  //                 to: route.path,
  //                 anchor: item.anchor,
  //                 hasChildren,
  //               }
  //             : undefined;
  //         }

  //         return {
  //           id: item.id,
  //           title: item.title,
  //           url: item.url,
  //           anchor: item.anchor,
  //           hasChildren,
  //         };
  //         // return item.url && item.url !== ''
  //         //   ? { id: item.id, title: item.title, to: item.url, anchor: item.anchor, hasChildren }
  //         //   : undefined;
  //       })
  //       .filter((i) => i !== undefined) as Array<Item>)
  //   : [];

  // return [];
}
