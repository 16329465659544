import clsx from 'clsx';
import React from 'react';
import Image from 'components/elements/Image';
import Text from 'components/elements/Text';
import { findElement, HeadlineViewData } from 'lib/elements';

import { ContainerProps } from './ContainerProps';

const EmployeeCard: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const headline = findElement<HeadlineViewData>(container, 'Headline');
  const image = findElement(container, 'Image');
  const text = findElement(container, 'Text');

  const imageSrc =
    image && image.images && image.images.length > 0 ? image.images[0].src : undefined;
  const style = {
    backgroundImage: imageSrc ? `url('${imageSrc}')` : 'none',
  };

  // mix-blend-luminosity opacity-40 hover:mix-blend-normal hover:opacity-100
  return (
    <div data-component="EmployeeCard">
      <div className="relative overflow-hidden">
        <div
          className={clsx(
            'aspect-w-8 aspect-h-8 bg-no-repeat bg-cover bg-top transform duration-2000 ease-in-out hover:scale-110',
            className
          )}
          style={style}
        >
          <a
            className="bg-ci-blue transition-opacity duration-200 ease-in-out focus:opacity-0 hover:opacity-0"
            href="#"
            onClick={(e) => e.preventDefault()}
          >
            {image && (
              <Image
                className="block h-full w-full object-cover object-top mix-blend-luminosity opacity-40"
                element={image.element}
              />
            )}
          </a>
        </div>
        {/* <div className="absolute inset-0 bg-ci-blue bg-blend-luminosity"></div> */}
        {headline && (
          <h4 className="absolute bottom-5 left-11 font-text font-semibold lg:text-lg text-white">
            {headline.data?.title}
            {headline.data?.subtitle && (
              <span className="block text-xs tracking-normal text-ci-orange">
                {headline.data.subtitle}
              </span>
            )}
          </h4>
        )}
      </div>
      {text && (
        <div className="px-11 py-7 text-xs leading-loose">
          <Text element={text.element} />
        </div>
      )}
    </div>
  );
};

export default EmployeeCard;
