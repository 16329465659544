import { User } from './User';

export interface PasswordRequirements {
  requiredLength: number;
  requireNonAlphanumeric: boolean;
  requireLowercase: boolean;
  requireUppercase: boolean;
  requireDigit: boolean;
}

export interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken: string | null;
  user: User | null;
  method: string;
  changePassword: (password: string, newPassword: string) => Promise<void>;
  delete: () => Promise<void>;
  getPasswordRequirements: () => Promise<PasswordRequirements>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (
    userName: string,
    password: string,
    email: string,
    phoneNumber: string
  ) => Promise<void>;
  update: (userName: string, email: string, phoneNumber: string) => Promise<void>;
}

export const defaultPasswordRequirements = {
  requiredLength: 6,
  requireNonAlphanumeric: true,
  requireLowercase: true,
  requireUppercase: true,
  requireDigit: true,
};
