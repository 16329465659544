import clsx from 'clsx';
import React from 'react';
import Link from 'components/Link';
import MaxWidth from 'components/MaxWidth';
import Text from 'components/elements/Text';
import { findElement, HeadlineViewData, LinkViewData } from 'lib/elements';

import { ContainerProps } from './ContainerProps';

const Hero: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const blendBackground = ''; // 'color';
  const headline = findElement<HeadlineViewData>(container, 'Headline');
  const link = findElement<LinkViewData>(container, 'Link');
  const text = findElement(container, 'Text');
  const image = findElement(container, 'Image');
  const imageSrc =
    image && image.images && image.images.length > 0 ? image.images[0].src : undefined;

  const bkgStyle = {
    backgroundImage: imageSrc ? `url('${imageSrc}')` : 'none',
    height: '66vw',
    minHeight: '320px',
    maxHeight: '820px',
  };

  return (
    <div
      className={clsx('relative h-full bg-no-repeat bg-center bg-cover', className)}
      style={bkgStyle}
      data-component="Hero"
    >
      {blendBackground && (
        <div className={`absolute inset-0 bg-ci-blue mix-blend-${blendBackground}`}></div>
      )}
      <MaxWidth className="min-h-full flex items-center">
        <div className="relative py-16 sm:py-24 lg:py-32">
          {headline && (
            <h1 className="text-3xl font-extrabold text-left tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white whitespace-pre-line">{headline.data?.title}</span>
              {headline.data?.subtitle && (
                <span className="block pt-2 text-ci-orange whitespace-pre-line">
                  {headline.data.subtitle}
                </span>
              )}
            </h1>
          )}
          {text && (
            <Text
              className="mt-6 text-xs text-white sm:mt-10 sm:max-w-3xl sm:text-sm sm:whitespace-pre-line md:text-base"
              element={text.element}
            />
          )}
          {link && link.data && (
            <div className="mt-6 mx-auto sm:mt-10 text-left">
              <Link
                className="text-3xs tracking-widest font-semibold text-white uppercase md:text-2xs"
                {...link?.data}
              >
                {link.data.title || 'Mehr erfahren'}
              </Link>
            </div>
          )}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Hero;
