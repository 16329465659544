import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
  dark?: boolean;
}

const LanguageMenu: React.FC<Props> = ({ className, dark }: Props) => {
  const currentLanguage = 'de';
  const languages = ['de', 'en'];

  return (
    <ul className={clsx('flex justify-end', className)}>
      {languages.map((lc) => (
        <li
          key={lc}
          className={clsx(
            'text-3xs sm:text-2xs font-extrabold uppercase pl-1',
            lc === currentLanguage ? 'opacity-100' : 'opacity-50',
            dark ? 'text-ci-gray' : 'text-white'
          )}
        >
          <a href="/">{lc}</a>
        </li>
      ))}
    </ul>
  );
};

export default LanguageMenu;
