import { combineUrl } from 'routes';
import { store } from 'store';
import { ElementDocument } from 'types';

export interface DocumentViewData {
  id: string;
  documentId: string;
  title?: string;
  description?: string;
  mimeType?: string;
  size: number;
  src: string;
}

/**
 * Returns view data for the specified document.
 * @param document to get view data for.
 * @returns Document view data.
 */
export function getDocumentViewData(document: ElementDocument): DocumentViewData | undefined {
  return document.document
    ? {
        id: document.id,
        documentId: document.document.id,
        title: document.title || document.document.title,
        description: document.description || document.document.description,
        mimeType: document.document.mimeType,
        size: document.document.size,
        src: document.document.url
          ? document.document.url
          : combineUrl(
              store.configuration.mediaUrl,
              'documents',
              document.document.path || '',
              document.document.name
            ),
      }
    : undefined;
}

/**
 * Returns an array of view data for the specified documents.
 * @param documents to get view data for.
 * @returns Array of documents view data.
 */
export function getDocumentsViewData(documents: Array<ElementDocument>): Array<DocumentViewData> {
  return documents
    ? (documents
        .map((d) => getDocumentViewData(d))
        .filter((d) => d != undefined) as Array<DocumentViewData>)
    : [];
}
