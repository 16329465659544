import React, { createContext, useEffect, useMemo, useState, ReactNode, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'jsonapi-react';
import { createRoutes, getRouteTo, Route } from 'routes';
import { StringMap, Website, WebsiteBinding } from 'types';

interface Props {
  baseUrl?: string;
  children?: ReactNode;
}

interface State {
  data?: Website;
  errors?: StringMap[];
  isLoading?: boolean;
  isFetching?: boolean;
  scrollTo?: string;
  routes: Route[];
  getRouteTo: (pageId: string, anchor?: string) => string;
  setScrollTo: (elementId?: string) => void;
}

const initialSiteState: State = {
  routes: [],
  getRouteTo: () => '',
  setScrollTo: () => {},
};

export const SiteContext = createContext<State>(initialSiteState);

export const SiteProvider = ({ baseUrl, children }: Props): JSX.Element => {
  const [routes, setRoutes] = useState<Route[]>([]);
  const [scrollTo, setScrollTo] = useState<string | undefined>(window && window.location.hash);
  const url = baseUrl ? new URL(baseUrl) : location;
  const fetch = useQuery<Array<WebsiteBinding>>([
    'websitebindings',
    {
      filter: `equals(host,'${url.hostname}')`,
      include: ['website', 'website.pages', 'website.pages.type'],
    },
  ]);

  const website = useMemo(
    () => (Array.isArray(fetch.data) && fetch.data.length > 0 ? fetch.data[0].website : undefined),
    [fetch.data]
  );

  const getRouteToInternal = useCallback(
    (pageId: string, anchor?: string) => getRouteTo(routes, pageId, anchor),
    [routes]
  );

  // Side effects
  useEffect(() => {
    // Create routes based on pages
    setRoutes(website && website.pages ? createRoutes(website.pages) : []);
  }, [website]);

  return (
    <SiteContext.Provider
      value={{
        data: website,
        errors: fetch.error ? [fetch.error] : fetch.errors,
        isLoading: fetch.isLoading,
        isFetching: fetch.isFetching,
        routes,
        scrollTo,
        getRouteTo: getRouteToInternal,
        setScrollTo,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

SiteProvider.propTypes = {
  children: PropTypes.node,
};

export default SiteContext;
