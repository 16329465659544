import clsx from 'clsx';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Containers from 'components/containers';
import HeroContainer from 'components/containers/Hero';
import { ContainerProvider } from 'contexts/ContainerContext';
import { useContent } from 'hooks/useContent';
import { useScrollTo } from 'hooks/useScrollTo';

const Hero: React.FC = () => {
  const { section, getContainers } = useContent();
  const { scrollRef } = useScrollTo(section?.anchorName);
  const [slide, setSlide] = useState(0);
  const containers = section ? getContainers(section.id) : [];

  return (
    <section
      // id={section?.anchorName?.toLocaleLowerCase()}
      className="relative overflow-hidden"
      ref={scrollRef}
      // style={style}
    >
      {section && containers.length === 1 && <Containers sectionId={section.id} />}
      {section && containers.length > 1 && (
        <Carousel
          autoPlay
          emulateTouch
          infiniteLoop
          interval={5000}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeable
          selectedItem={slide}
          onChange={setSlide}
        >
          {containers.map((container) => (
            <ContainerProvider key={container.id} containerId={container.id}>
              <HeroContainer container={container} />
            </ContainerProvider>
          ))}
        </Carousel>
      )}
      {/* Carousel dots */}
      {section && containers.length > 1 && (
        <div className="absolute left bottom-8 md:bottom-10 lg:bottom-12 w-full">
          <ul className="flex space-x-2 lg:space-x-3 max-w-[75rem] mx-auto px-4 sm:px-6 lg:px-8">
            {containers.map((container, index) => (
              <li
                key={index}
                className={clsx(
                  'w-2 h-2 lg:w-[11px] lg:h-[11px] bg-white rounded-full',
                  index === slide ? '' : 'bg-opacity-50 hover:bg-opacity-100'
                )}
                value={index}
                role="button"
                onClick={() => setSlide(index)}
              />
            ))}
          </ul>
        </div>
      )}
    </section>
  );
};

export default Hero;
