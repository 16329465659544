import React from 'react';

interface Props {
  componentName?: string;
}

const NotFound: React.FC<Props> = ({ componentName }: Props) => {
  console.error(`Element "${componentName || 'undefined'}" not found`);
  return process.env.NODE_ENV === 'development' ? (
    <div className="hidden">Element &quot;{componentName}&quot; not found</div>
  ) : null;
};

export default NotFound;
