import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { animated, config, useTransition } from '@react-spring/web';

interface Props {
  children?: React.ReactNode;
}

const PageTransition: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { ...config.gentle, duration: 750 },
  } as any);

  return (
    <>
      {transitions((props, item) => (
        <animated.div style={props}>
          <Switch location={item}>{children}</Switch>
        </animated.div>
      ))}
    </>
  );
};

export default PageTransition;
