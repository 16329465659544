import React from 'react';
import Containers from 'components/containers';
import MaxWidth from 'components/MaxWidth';
import { useContent } from 'hooks/useContent';
import { useScrollTo } from 'hooks/useScrollTo';

const Products: React.FC = () => {
  const { section } = useContent();
  const { scrollRef } = useScrollTo(section?.anchorName);
  return (
    <section
      // id={section?.anchorName?.toLocaleLowerCase()}
      className="py-10 sm:py-14 lg:py-28 even:bg-gray-50"
      ref={scrollRef}
    >
      <MaxWidth className="grid grid-cols-1 gap-10 gap-y-7 md:grid-cols-2 lg:grid-cols-4">
        {section && <Containers sectionId={section.id} />}
      </MaxWidth>
    </section>
  );
};

export default Products;
