import React from 'react';
import Header from 'components/Header';

interface Props {
  children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <Header />
      <main role="main" className="relative pt-28" style={{ minHeight: 'calc(100% - 700px)' }}>
        {children}
      </main>
    </>
  );
};

export default Layout;
