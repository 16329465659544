import React from 'react';
import Containers from 'components/containers';
import MaxWidth from 'components/MaxWidth';
import { useContent } from 'hooks/useContent';
import { useScrollTo } from 'hooks/useScrollTo';

const Default: React.FC = () => {
  const { section } = useContent();
  const { scrollRef } = useScrollTo(section?.anchorName);
  // console.log(`Section[${section?.id}]`, section);

  return (
    <section
      // id={section?.anchorName?.toLocaleLowerCase()}
      className="py-10 sm:py-14 lg:py-28 even:bg-gray-50"
      ref={scrollRef}
    >
      <MaxWidth>{section && <Containers sectionId={section.id} />}</MaxWidth>
    </section>
  );
};

export default Default;

{
  /* <section>
<h2 className="font-headline font-bold text-lg">{section?.type?.name}</h2>
{section && <Containers sectionId={section.id} />}
</section> */
}
