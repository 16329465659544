import { catalog as containers } from './containers';
import { catalog as elements } from './elements';
import { catalog as forms } from './forms';
import { catalog as pages } from './pages';
import { catalog as sections } from './sections';

export const catalog = {
  containers,
  elements,
  forms,
  pages,
  sections,
};
