import clsx from 'clsx';
import React from 'react';
import Icons from 'components/Icons';
import Document from 'components/elements/Document';
import Image from 'components/elements/Image';
import Text from 'components/elements/Text';
import { findElement, findElements, HeadlineViewData } from 'lib/elements';
import { tryParse } from 'lib/tryParse';

import { ContainerProps } from './ContainerProps';

interface OptionProps {
  alignment?: 'left' | 'right';
  icons?: string[];
  imageSize?: 'small' | 'medium' | 'large';
}

const TextImage: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const props = tryParse<OptionProps>(container.props);
  const alignment = (props && props.alignment) || 'right';
  const icons = (props && props.icons) || false;
  const variant = (props && props.imageSize) || 'medium';
  const variants = {
    // Grid cols, text col span, image col span, image aspect ratio
    large: ['lg:grid-cols-3', '', 'lg:col-span-2', 'aspect-w-14 aspect-h-9'],
    medium: ['lg:grid-cols-2', '', '', 'aspect-w-1 aspect-h-1'],
    small: ['lg:grid-cols-3', 'lg:col-span-2', '', 'aspect-w-1 aspect-h-1'],
  };

  const headline = findElement<HeadlineViewData>(container, 'Headline');
  const texts = findElements(container, 'Text');
  const image = findElement(container, 'Image');
  const document = findElement(container, 'Document');

  return (
    <div
      className={clsx(
        'pt-6 sm:pt-14 lg:pt-28 first:pt-0 first:sm:pt-0 first:lg:pt-0 grid grid-cols-1 gap-10 gap-y-7',
        variants[variant][0],
        className
      )}
      data-component="TextImage"
    >
      <div className={variants[variant][1]}>
        {headline && (
          <h3
            className={clsx(
              'relative mb-3 text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-4xl whitespace-pre-line',
              { 'mt-10': headline.data?.subtitle }
            )}
          >
            {headline.data?.title}
            {headline.data?.subtitle && (
              <span className="absolute block -top-10 left-0 font-text text-3xs tracking-3widest uppercase">
                {'// '}
                {headline.data.subtitle}
              </span>
            )}
          </h3>
        )}
        {texts && texts.map((t) => <Text key={t.element.id} element={t.element} />)}
        {icons && <Icons icons={icons} title="Anwendung" />}
        {document && <Document element={document.element} />}
      </div>
      {image && (
        <div
          className={clsx(
            'overflow-hidden h-0',
            alignment === 'left' ? 'lg:order-first' : '',
            variants[variant][2],
            variants[variant][3]
          )}
        >
          <Image className={clsx('block w-full h-full object-cover')} element={image.element} />
        </div>
      )}
    </div>
  );
};

export default TextImage;
