import clsx from 'clsx';
import React from 'react';
import { getElementViewData } from 'lib/elements';
import { ElementProps } from './ElementProps';

const Image: React.FC<ElementProps> = ({ element, className }: ElementProps) => {
  const viewData = getElementViewData(element);
  const image = viewData.images.length > 0 ? viewData.images[0] : undefined;

  return image ? (
    <img className={clsx('w-full', className)} src={image.src} alt={image.title} />
  ) : null;
};

export default Image;
