import React from 'react';

const LoadingScreen: React.FC = () => {
  const style = {
    borderTopColor: '#002b62',
    borderLeftColor: '#002b62',
    borderRightColor: '#002b62',
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <div
        className="loader animate-spin ease-linear rounded-full border-8 border-t-8 border-t-ci-blue border-gray-200 h-32 w-32"
        style={style}
      />
    </div>
  );
};

export default LoadingScreen;
