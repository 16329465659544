/**
 * Tries to parse text as JSON. If successful it returns the parsed object, otherwise undefined.
 * @param text to parse.
 * @returns the JSON object if sucessful, otherwise undefined.
 */
export function tryParse<Result = any>(text?: string): Result | undefined {
  if (text) {
    try {
      return JSON.parse(text) as Result;
    } catch {}
  }

  return undefined;
}
