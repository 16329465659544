import React from 'react';
import FooterMenu from 'components/FooterMenu';
import Logo from 'components/Logo';
import MaxWidth from 'components/MaxWidth';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="relative bg-ci-blue bg-center bg-no-repeat"
      style={{ backgroundImage: 'url(/images/footer.jpg)' }}
    >
      <MaxWidth className="flex items-center lg:h-[700px]">
        <div className="flex justify-between py-12 md:py-24 w-full">
          <FooterMenu />
        </div>
      </MaxWidth>
      <div className="absolute inset-0 pointer-events-none">
        <div className="flex justify-center items-center h-full">
          <div className="text-center">
            <Logo className="mx-auto" defaultSize />
            <h3 className="hidden lg:block mt-16 font-handwritten text-8xl text-ci-orange">
              <span className="inline-block transform translate-x-6 -translate-y-4 -rotate-6">
                Think nuts
              </span>
              <span className="inline-block transform -translate-x-6 translate-y-4 -rotate-6">
                just Moll
              </span>
            </h3>
          </div>
        </div>
        <div className="absolute bottom-4 lg:bottom-8 left-0 w-full text-xs text-ci-blue-gray opacity-50 text-center uppercase whitespace-nowrap">
          &copy; {currentYear} Moll Marzipan GmbH
        </div>
      </div>
    </footer>
  );
};

export default Footer;
