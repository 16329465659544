import React from 'react';
import Footer from 'components/Footer';
import Sections from 'components/sections';
import { useContent } from 'hooks/useContent';

const Default: React.FC = () => {
  const { isReady } = useContent();
  return (
    <div className="absolute w-full">
      <Sections />
      {isReady && <Footer />}
    </div>
  );
};

export default Default;
