import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ApiProvider } from 'jsonapi-react';

import Site from 'components/Site';
import { AuthProvider } from 'contexts/NullAuthContext';
import { SiteProvider } from 'contexts/SiteContext';
import useApi from 'hooks/useApi';

const App: React.FC = () => {
  const { client } = useApi();

  return (
    <HelmetProvider>
      <AuthProvider>
        <ApiProvider client={client}>
          <SiteProvider baseUrl={window.location.toString()}>
            <Site />
          </SiteProvider>
        </ApiProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
