import React from 'react';
import Elements from 'components/elements';
import { useContent } from 'hooks/useContent';

const Default: React.FC = () => {
  const { container } = useContent();
  return container ? <Elements containerId={container.id} /> : null;
};

export default Default;
