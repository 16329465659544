import React from 'react';
import { HashLink } from 'components/HashLink';

interface Props {
  children?: React.ReactNode;
  activeClassName?: string;
  className?: string;
  to?: string;
  url?: string;
}

const Link: React.FC<Props> = ({ children, activeClassName, className, to, url }: Props) => {
  // Render external link
  if (url) {
    return (
      <a className={className} href={url} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return to ? (
    <HashLink activeClassName={activeClassName} className={className} to={to}>
      {children}
    </HashLink>
  ) : (
    <>{children}</>
  );

  // TODO: Scroll to content after content has been fetched
  // https://github.com/rafgraph/react-router-hash-link/issues/13
  // https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element/51828976#51828976
  /* eslint-disable */
  // return linkTo ? (
  //   // @ts-ignore
  //   <HashLink className={className} to={linkTo} smooth>
  //     {children}
  //   </HashLink>
  // ) : (
  //   <>{children}</>
  // );
  /* eslint-enable */
};

export default Link;
