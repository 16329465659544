import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
  defaultSize?: boolean;
}

const Logo: React.FC<Props> = ({ className, defaultSize }: Props) => (
  <svg
    className={clsx(
      'fill-current text-white',
      { 'w-14 sm:w-16 md:w-20 lg:w-24': defaultSize },
      className
    )}
    xmlns="http://www.w3.org/2000/svg"
    width="428"
    viewBox="0 0 428 158"
  >
    <path
      fillRule="evenodd"
      d="M144.8,1.33S100.86,8.12,61.56,22.39C35.29,31.94,11.1,45,3.49,61.07c-19.85,41.9,67.07,70.34,125.2,80.71a866.29,866.29,0,0,0,90.53,12.06c23,1.84,50.09,3.64,81.83,3.16,1.22,0,1.62-.15,1.69-.31s-.11-.34-.33-.41-3.59-.13-4.56-.15c-37.88-.85-62.45-5.07-94.58-10.39-17.7-2.94-44.33-10.06-59.25-16.3C37.17,84.81,93,33.75,101.49,27A187.41,187.41,0,0,1,140.66,3.65c5-2,7-2.53,6.92-2.73s-2.78.41-2.78.41Z"
    />
    <path
      fillRule="evenodd"
      d="M162.24,40.61l-25.66-.07-9.85,68.68h25.69l7.74-53.39s.41-7.69,8.33-7.06c6.2.5,7.44,6.38,6.92,11.33-.49,4.57-7.21,49.12-7.21,49.12h25.74l7.13-52s.56-9.48,8.89-8.75c7.85.69,7.14,9.63,6.4,14.85-.83,5.89-6.69,45.89-6.69,45.89h25.74S242,60.53,242.27,57s.11-16.71-11.35-16.76c-14.54-.08-20.72-.16-23.65.46a12.58,12.58,0,0,0-8.11,5.79,9.79,9.79,0,0,0-3.61-4.69c-2.57-1.6-4.75-1.52-9.75-1.47-14.59.15-17.39-.85-21.91,3.26-2.42,2.2-2.39,2.5-2.39,2.5l.74-5.49Z"
    />
    <polygon
      fillRule="evenodd"
      points="399.76 10.39 385.57 109.22 413.28 109.22 427.4 10.39 399.76 10.39 399.76 10.39"
    />
    <path
      fillRule="evenodd"
      d="M297.32,48.55s4.31,0,7.79,5.67c3.23,5.25,5.49,17.45,1.18,29.9-5.41,15.61-13.84,16.92-17.5,16.37-2.77-.41-13.42-6-9.85-28.48,1.47-9.27,5-15,7.95-18.42,5.09-6,10.43-5,10.43-5ZM253.94,91.28c-1,6.89-.62,11.71,3.23,15.07.95.83,3.29,2.91,11.69,2.94,15.38,0,40.24,0,45,0,3.38,0,10.68-3,13.46-12.43,1.17-4,5.28-33.83,5.59-36.76.45-4.38,1-7.72.1-11.4-1.55-6.31-6.72-8.16-10.64-8.16-11.2,0-43.78-.1-46.08,0s-8.77-.29-13.46,6.25c-1.16,1.62-2.55,4.11-3.58,9-1.42,6.61-2.71,17.64-5.31,35.53Z"
    />
    <polygon points="341.68 109.29 369.77 109.28 383.66 10.39 355.5 10.39 341.68 109.29 341.68 109.29" />
  </svg>
);

export default Logo;
