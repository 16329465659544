export const configuration = {
  apiEndpoint:
    process.env.REACT_APP_API_ENDPOINT ||
    (window
      ? `${window.location.protocol}//${window.location.hostname}/api`
      : 'http://localhost/api'),
  auth:
    process.env.NODE_ENV === 'development'
      ? {
          username: process.env.REACT_APP_USER_NAME,
          password: process.env.REACT_APP_USER_PASSWORD,
          email: process.env.REACT_APP_USER_EMAIL,
        }
      : undefined,
  authEndpoint:
    process.env.REACT_APP_AUTH_ENDPOINT ||
    (window
      ? `${window.location.protocol}//${window.location.hostname}/account`
      : 'http://localhost/account'),
  isDevelopment: process.env.NODE_ENV === 'development',
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  mediaUrl:
    process.env.REACT_APP_MEDIA_URL ||
    (window
      ? `${window.location.protocol}//${window.location.hostname}/media`
      : 'http://localhost/media'),
  uploadMaxFileSize: 31457280,
};
