import React from 'react';
import { CatalogComponent } from 'react-component-catalog';
import { ElementProps } from './ElementProps';
import Default from './NotFound';

const Form: React.FC<ElementProps> = ({ element, className }: ElementProps) => {
  return element && element.data ? (
    <CatalogComponent
      key={element.id}
      component={`forms.${element.data}`}
      fallbackComponent={Default}
      /* Other props */
      element={element}
      className={className}
      componentName={element.type?.name}
    />
  ) : null;
};

export default Form;
