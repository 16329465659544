import React, { useMemo } from 'react';
import { CatalogComponent } from 'react-component-catalog';
import { useContent } from 'hooks/useContent';
import { ElementProps } from './ElementProps';
import Default from './NotFound';

import Form from './Form';
import Headline from './Headline';
import Image from './Image';
import Map from './Map';
import Text from './Text';

export const catalog = {
  Form,
  Headline,
  Image,
  Map,
  Text,
};

interface Props {
  containerId: string;
  typeFilter?: string[];
  elementProps?: ElementProps;
}

const Elements: React.FC<Props> = ({ containerId, typeFilter, elementProps }: Props) => {
  const { getElements } = useContent();
  const filteredElements = useMemo(
    () =>
      typeFilter
        ? getElements(containerId).filter((e) => e.type?.name && typeFilter.includes(e.type.name))
        : getElements(containerId),
    [getElements, containerId, typeFilter]
  );

  // TODO: Implement default element as fallback
  return (
    <>
      {filteredElements.map((element) => (
        <CatalogComponent
          key={element.id}
          component={`elements.${element.type?.name}`}
          fallbackComponent={Default}
          /* Other props */
          element={element}
          componentName={element.type?.name}
          {...elementProps}
        />
      ))}
    </>
  );
};

export default Elements;
