import clsx from 'clsx';
import React from 'react';
import Markdown from 'components/Markdown';
import { getElementViewData } from 'lib/elements';
import { ElementProps } from './ElementProps';

const Text: React.FC<ElementProps> = ({ element, className }: ElementProps) => {
  if (!element.dataFormat || element.dataFormat === 'text/markdown') {
    // console.log(`Render text as markdown [${element.id}]`, element);
    return <Markdown className={className} markdown={element.data || ''} />;
  }

  const viewData = getElementViewData(element);

  // TODO: Render data dependent on the data type: html, markdown, text
  return <p className={clsx('whitespace-pre-line', className)}>{viewData.data}</p>;
};

export default Text;
