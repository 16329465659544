import clsx from 'clsx';
import React from 'react';
import { findElement, HeadlineViewData } from 'lib/elements';
import { tryParse } from 'lib/tryParse';

import { ContainerProps } from './ContainerProps';

interface OptionProps {
  background?: 'gray' | 'white' | 'transparent';
}

const Headline: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const props = tryParse<OptionProps>(container.props);
  const background = (props && props.background) || 'transparent';
  const twBackground =
    background === 'gray' ? 'bg-gray-50' : background === 'white' ? 'bg-white' : '';

  const headline = findElement<HeadlineViewData>(container, 'Headline');

  return (
    <div
      className={clsx(
        'flex justify-center items-center pt-6 sm:pt-14 lg:pt-28 text-center',
        twBackground,
        className
      )}
      data-component="Headline"
    >
      {/* <div className=" mt-8 mb-3 "></div> */}
      {headline && (
        <div>
          {headline.data?.subtitle && (
            <span className="block font-text font-extrabold text-3xs tracking-3widest uppercase text-center">
              {headline.data.subtitle}
            </span>
          )}
          <h2 className="pt-4 font-extrabold tracking-2tight text-4xl sm:text-5xl lg:text-6xl whitespace-pre-line">
            {headline.data?.title}
          </h2>
        </div>
      )}
    </div>
  );
};

export default Headline;
