import { useCallback, useContext, useMemo } from 'react';
import { ContainerContext } from 'contexts/ContainerContext';
import { PageContext } from 'contexts/PageContext';
import { SectionContext } from 'contexts/SectionContext';
import { SiteContext } from 'contexts/SiteContext';
import { Container, Element, Page, Section } from 'types';

interface State {
  isReady: boolean;
  page?: Page;
  section?: Section;
  container?: Container;
  getSections: () => Section[];
  getContainers: (sectionId: string) => Container[];
  getElements: (containerId: string) => Element[];
}

/**
 * Provides content for the current page.
 * The hook require the SiteContext and PageContext providers set.
 */
export function useContent(): State {
  const { data: website } = useContext(SiteContext);
  const { data: sections, errors, isLoading, pageId } = useContext(PageContext);
  const { sectionId } = useContext(SectionContext);
  const { containerId } = useContext(ContainerContext);

  // Find the current page
  const page = useMemo(() => {
    return website && website.pages ? website.pages.find((p) => p.id == pageId) : undefined;
  }, [website, pageId]);

  // Memoize ready state
  const isReady = useMemo(
    () => !errors && !isLoading && sections != null,
    [errors, isLoading, sections]
  );

  // Container section map to find containers faster
  const containerSectionMap = useMemo(() => {
    const map = new Map<string, string>();
    sections?.forEach((section) => {
      section.containers?.forEach((container) => {
        map.set(container.id, section.id);
      });
    });
    return map;
  }, [sections]);

  // Helper functions
  const getSections = useCallback((): Section[] => sections || [], [sections]);
  const getContainers = useCallback(
    (sectionId: string): Container[] => sections?.find((s) => s.id == sectionId)?.containers || [],
    [sections]
  );
  const getElements = useCallback(
    (containerId: string): Element[] => {
      const sectionId = containerSectionMap.get(containerId);
      const containers = sectionId ? getContainers(sectionId) : [];
      return containers.find((c) => c.id == containerId)?.elements || [];
    },
    [containerSectionMap, getContainers]
  );

  // Current section and container if available
  const section = useMemo(
    () => (isReady ? getSections().find((s) => s.id == sectionId) : undefined),
    [isReady, sectionId, getSections]
  );
  const container = useMemo(
    () => (isReady ? getContainers(sectionId).find((c) => c.id == containerId) : undefined),
    [isReady, containerId, sectionId, getContainers]
  );

  return {
    isReady,
    page,
    section,
    container,
    getSections,
    getContainers,
    getElements,
  };
}

export default useContent;
