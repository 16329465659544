import React, { createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';

interface Props {
  containerId: string;
  children?: ReactNode;
}

interface State {
  containerId: string;
}

const initialContainerState: State = {
  containerId: '',
};

export const ContainerContext = createContext<State>(initialContainerState);

export const ContainerProvider = ({ containerId, children }: Props): JSX.Element => {
  return <ContainerContext.Provider value={{ containerId }}>{children}</ContainerContext.Provider>;
};

ContainerProvider.propTypes = {
  children: PropTypes.node,
};

export default ContainerContext;
