import clsx from 'clsx';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { getElementViewData } from 'lib/elements';
import { ElementProps } from './ElementProps';

const Document: React.FC<ElementProps> = ({ element, className }: ElementProps) => {
  const viewData = getElementViewData(element);
  return viewData.documents.length > 0 ? (
    <div className="pt-4">
      {viewData.documents.map((document, index) => (
        <a
          key={document.id || index}
          className={clsx(
            'flex justify-start items-center space-x-2 font-extrabold text-sm leading-7 hover:text-ci-orange',
            className
          )}
          href={document.src}
          rel="noreferrer"
          target="_blank"
        >
          {/* <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            fill="currentColor"
          >
            <path d="M9.92,0a1.48,1.48,0,0,0,0,.21V11.45L14,7.1l1.33,1.21-1,1.1c-1.31,1.4-2.63,2.79-3.93,4.19a1.74,1.74,0,0,1-2.7,0C6.05,11.9,4.44,10.18,2.83,8.47l-.14-.16L4,7.09l4.07,4.34a1.06,1.06,0,0,0,0-.15V0Z" />
            <path d="M.88,18c0-.53,0-1.05,0-1.58v-.21H17.12V18Z" />
          </svg> */}
          <FontAwesomeIcon icon={faArrowDownToLine} className="text-lg" />
          <span>{document.title}</span>
        </a>
      ))}
    </div>
  ) : null;
};

export default Document;
