import clsx from 'clsx';
import React, { useContext, useMemo } from 'react';
import { Transition } from '@headlessui/react';
import { useQuery } from 'jsonapi-react';

import MaxWidth from 'components/MaxWidth';
import Menu from 'components/Menu';
import { SiteContext } from 'contexts/SiteContext';
import useLocationChange from 'hooks/useLocationChange';
import { getMenuItemsViewData } from 'lib/menu';
import * as Types from 'types';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const MainMenu: React.FC<Props> = ({ open, onClose }: Props) => {
  const { data: website, routes } = useContext(SiteContext);
  useLocationChange(() => {
    if (onClose) {
      onClose();
    }
  });

  const fetch = useQuery<Array<Types.Menu>>(
    website && [
      'menus',
      {
        filter: `and(equals(websiteId,'${website.id}'),equals(name,'MainMenu'))`,
        include: ['items' /*, 'items.section'*/],
      },
    ]
  );

  const menuItems = useMemo(
    () =>
      fetch.data && fetch.data.length > 0 && fetch.data[0].items
        ? getMenuItemsViewData(fetch.data[0].items, routes)
        : [],
    [fetch.data, routes]
  );

  return (
    <Transition
      show={open}
      as={React.Fragment}
      enter="transition ease-out duration-300"
      enterFrom="-translate-y-5"
      enterTo="translate-y-0"
      leave="transition ease-in duration-100"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-5"
    >
      <div className="fixed overflow-y-auto inset-0 sm:inset-auto sm:top-28 sm:left-0 sm:w-full box-border z-20 bg-white transform">
        <MaxWidth
          className={clsx('opacity-0 transition-opacity duration-300 ease-in-out', {
            'opacity-100': open,
          })}
        >
          {menuItems && (
            <Menu
              classNames={[
                'pb-10 sm:pt-0 sm:pb-28 sm:grid sm:gap-10 sm:grid-cols-2 lg:pt-16 lg:grid-cols-4',
                'sm:pt-4',
              ]}
              // activeClassNames={['text-ci-orange', 'text-ci-orange']}
              itemClassNames={[
                'block pt-6 sm:pt-0 font-headline font-extrabold tracking-2tight text-lg sm:text-xl lg:text-2xl whitespace-nowrap overflow-ellipsis overflow-hidden hover:text-ci-orange',
                'block whitespace-nowrap overflow-ellipsis overflow-hidden hover:text-ci-orange',
              ]}
              items={menuItems}
            />
          )}
        </MaxWidth>
      </div>
    </Transition>
  );
};

export default MainMenu;
