import { combineUrl } from 'routes';
import { store } from 'store';
import { ElementImage } from 'types';

export interface ImageViewData {
  id: string;
  imageId: string;
  title?: string;
  description?: string;
  mimeType?: string;
  size: number;
  height: number;
  width: number;
  src: string;
}

/**
 * Returns view data for the specified image.
 * @param image to get view data for.
 * @returns Image view data.
 */
export function getImageViewData(image: ElementImage): ImageViewData | undefined {
  return image.image
    ? {
        id: image.id,
        imageId: image.image.id,
        title: image.title || image.image.title,
        description: image.description || image.image.description,
        mimeType: image.image.mimeType,
        size: image.image.size,
        height: image.image.height,
        width: image.image.width,
        src: image.image.url
          ? image.image.url
          : combineUrl(
              store.configuration.mediaUrl,
              'images',
              image.image.path || '',
              image.image.name
            ),
      }
    : undefined;
}

/**
 * Returns an array of view data for the specified images.
 * @param images to get view data for.
 * @returns Array of images view data.
 */
export function getImagesViewData(images: Array<ElementImage>): Array<ImageViewData> {
  return images
    ? (images.map((i) => getImageViewData(i)).filter((i) => !!i) as Array<ImageViewData>)
    : [];
}
