import React from 'react';
import { getElementViewData, HeadlineViewData } from 'lib/elements';
import { ElementProps } from './ElementProps';

const Headline: React.FC<ElementProps> = ({ element, className }: ElementProps) => {
  const viewData = getElementViewData<HeadlineViewData>(element);

  return viewData.data ? (
    <h2 className={className}>
      {viewData.data?.title}
      {viewData.data?.subtitle && <span>{viewData.data.subtitle}</span>}
    </h2>
  ) : null;
};

export default Headline;
