import clsx from 'clsx';
import React, { useContext } from 'react';
import Image from 'components/elements/Image';
import Link from 'components/Link';
import { PageContext } from 'contexts/PageContext';
import { SiteContext } from 'contexts/SiteContext';
import { findElement, LinkViewData } from 'lib/elements';

import { ContainerProps } from './ContainerProps';

const ProductCard: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const { getRouteTo } = useContext(SiteContext);
  const { pageId } = useContext(PageContext);
  const image = findElement(container, 'Image');
  const link = findElement<LinkViewData>(container, 'Link');
  const linkTo =
    link?.data && (link.data.pageId || link.data.anchor)
      ? getRouteTo(link.data.pageId || pageId, link.data.anchor)
      : undefined;
  // const imageSrc =
  //   image && image.images && image.images.length > 0 ? image.images[0].src : undefined;
  // const style = {
  //   backgroundImage: imageSrc ? `url('${imageSrc}')` : 'none',
  // };

  return (
    <div className={clsx('relative overflow-hidden', className)} data-component="ProductCard">
      <div className="md:aspect-w-8 md:aspect-h-8">
        <Link className="block w-full h-full" url={link?.data?.url} to={linkTo}>
          {image && (
            <Image
              className="h-full w-full object-cover transition transform duration-300 ease-in-out hover:opacity-25 hover:scale-110"
              element={image.element}
            />
          )}
          {link && link.data && (
            <p className="absolute bottom-5 left-11 font-semibold lg:text-lg">{link.data.title}</p>
          )}
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
