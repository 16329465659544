import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { AuthState, defaultPasswordRequirements } from 'types';

interface AuthProviderProps {
  children: React.ReactNode;
}

const initialAuthState: AuthState = {
  isAuthenticated: true,
  isInitialised: true,
  accessToken: null,
  user: {
    id: 'super',
    avatar: '',
    email: 'hello@eitido.de',
    userName: 'superuser',
    ...store.configuration.auth,
    password: undefined,
  },
  method: 'Null',
  changePassword: () => Promise.resolve(),
  delete: () => Promise.resolve(),
  getPasswordRequirements: () => Promise.resolve(defaultPasswordRequirements),
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
};

const AuthContext = createContext<AuthState>(initialAuthState);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, setState] = useState<AuthState>(initialAuthState);

  const login = (): Promise<void> => {
    setState((prev) => ({
      ...prev,
      isAuthenticated: true,
    }));
    return Promise.resolve();
  };

  const logout = (): Promise<void> => {
    setState((prev) => ({
      ...prev,
      isAuthenticated: false,
    }));
    return Promise.resolve();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
