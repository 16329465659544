import React, { useContext, Suspense } from 'react';
import { CatalogComponent, CatalogProvider } from 'react-component-catalog';
import { BrowserRouter, Route } from 'react-router-dom';

import { catalog } from 'components/catalog';
import Layout from 'components/Layout';
import LoadingScreen from 'components/LoadingScreen';
import MetaTags from 'components/MetaTags';
import PageTransition from 'components/PageTransition';
import ScrollToTop from 'components/ScrollToTop';
import DefaultPage from 'components/pages/Default';
import { PageProvider } from 'contexts/PageContext';
import { SiteContext } from 'contexts/SiteContext';

const App: React.FC = () => {
  const { data, routes } = useContext(SiteContext);

  if (!data || routes.length == 0) {
    return <LoadingScreen />;
  }

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LoadingScreen />}>
          <CatalogProvider catalog={catalog}>
            <Layout>
              <ScrollToTop />
              <PageTransition>
                {routes.length > 0 &&
                  routes.map((route) => (
                    <Route
                      key={route.pageId}
                      path={route.path}
                      exact={route.exact}
                      render={() => (
                        <PageProvider pageId={route.pageId}>
                          {data && (
                            <MetaTags
                              title={route.title ? `${route.title} | ${data.title}` : data.title}
                              metadata={route.meta}
                            />
                          )}
                          <CatalogComponent
                            component={`pages.${route.componentName}`}
                            fallbackComponent={DefaultPage}
                          />
                        </PageProvider>
                      )}
                    />
                  ))}
              </PageTransition>
            </Layout>
          </CatalogProvider>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
