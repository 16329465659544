import { useContext, useEffect, useRef } from 'react';
import { SiteContext } from 'contexts/SiteContext';

export interface State {
  scrollRef: React.RefObject<HTMLElement>;
}

/**
 * Returns true if the html element is interactive.
 */
function isInteractiveElement(element: HTMLElement): boolean {
  const formTags = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
  const linkTags = ['A', 'AREA'];
  return (
    (formTags.includes(element.tagName) && !element.hasAttribute('disabled')) ||
    (linkTags.includes(element.tagName) && element.hasAttribute('href'))
  );
}

/**
 * A hook that easily supports scroll to an react element.
 * @param anchor value to test against.
 * @param behavior of scroll to.
 * @returns a reference object to the element that should scrolled to if set.
 */
export const useScrollTo = (anchor?: string, behavior: ScrollBehavior = 'smooth'): State => {
  const { scrollTo, setScrollTo } = useContext(SiteContext);
  const scrollRef = useRef<HTMLElement>(null);

  // TODO: Consider to use useLayoutEffect
  useEffect(() => {
    if (anchor && scrollTo && '#' + anchor.toLowerCase() === scrollTo) {
      // console.log('Scroll to', scrollTo, scrollRef.current, window.location.hash);
      // TODO: Check what happens when the current ref element is not available
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior });

        // update focus to where the page is scrolled to
        // unfortunately this doesn't work in safari (desktop and iOS) when blur() is called
        const originalTabIndex = scrollRef.current.getAttribute('tabindex');
        if (originalTabIndex === null && !isInteractiveElement(scrollRef.current)) {
          scrollRef.current.setAttribute('tabindex', '-1');
        }
        scrollRef.current.focus({ preventScroll: true });
        if (originalTabIndex === null && !isInteractiveElement(scrollRef.current)) {
          // for some reason calling blur() in safari resets the focus region to where it was previously,
          // if blur() is not called it works in safari, but then are stuck with default focus styles
          // on an element that otherwise might never had focus styles applied, so not an option
          scrollRef.current.blur();
          scrollRef.current.removeAttribute('tabindex');
        }

        // Reset scroll to
        setScrollTo();
      } else if (process.env.NODE_ENV === 'development') {
        console.error(
          `The hook useScrollTo can't scroll to anchor ${anchor}, because the ref is not set.`
        );
      }
    }
  }, [anchor, behavior, scrollTo, setScrollTo]);

  return { scrollRef };
};

export default useScrollTo;
