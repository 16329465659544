import React from 'react';
import Containers from 'components/containers';
import { useContent } from 'hooks/useContent';
import { useScrollTo } from 'hooks/useScrollTo';

const GoogleMap: React.FC = () => {
  const { section } = useContent();
  const { scrollRef } = useScrollTo(section?.anchorName);
  return (
    <section /*id={section?.anchorName?.toLocaleLowerCase()}*/ ref={scrollRef}>
      {section && <Containers sectionId={section.id} />}
    </section>
  );
};

export default GoogleMap;
