import React, { createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'jsonapi-react';
import { Section, StringMap } from 'types';

interface Props {
  pageId: string;
  children?: ReactNode;
}

interface State {
  pageId: string;
  data?: Array<Section>;
  errors?: StringMap[];
  isLoading?: boolean;
  isFetching?: boolean;
}

const initialPageState: State = {
  pageId: '',
};

export const PageContext = createContext<State>(initialPageState);

export const PageProvider = ({ pageId, children }: Props): JSX.Element => {
  const fetch = useQuery<Array<Section>>([
    'sections',
    {
      filter: `equals(pageId,'${pageId}')`,
      include: [
        'type',
        'containers',
        'containers.type',
        'containers.elements',
        'containers.elements.type',
        'containers.elements.documents',
        'containers.elements.documents.document',
        'containers.elements.images',
        'containers.elements.images.image',
        'images',
        'images.image',
      ],
    },
  ]);

  return (
    <PageContext.Provider
      value={{
        pageId,
        data: fetch.data,
        errors: fetch.error ? [fetch.error] : fetch.errors,
        isLoading: fetch.isLoading,
        isFetching: fetch.isFetching,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

PageProvider.propTypes = {
  children: PropTypes.node,
};

export default PageContext;
