import React from 'react';
import Containers from 'components/containers';
import { useContent } from 'hooks/useContent';
import { useScrollTo } from 'hooks/useScrollTo';

const Header: React.FC = () => {
  const { section } = useContent();
  const { scrollRef } = useScrollTo(section?.anchorName);
  return (
    <section
      /*id={section?.anchorName?.toLocaleLowerCase()}*/ className="odd:bg-gray-50"
      ref={scrollRef}
    >
      {section && <a id={section.id}></a>}
      {section && <Containers sectionId={section.id} />}
    </section>
  );
};

export default Header;
