import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Location } from 'history';
import { SiteContext } from 'contexts/SiteContext';

interface Props {
  children?: React.ReactNode;
  activeClassName?: string;
  className?: string;
  target?: string;
  to: string | Location;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const HashLink: React.FC<Props> = ({
  children,
  activeClassName,
  className,
  target,
  to,
  onClick,
}: Props) => {
  const { setScrollTo } = useContext(SiteContext);

  const handleClick = (e: React.MouseEvent<any>): void => {
    let hashFragment = '';
    if (typeof to === 'string' && to.includes('#')) {
      hashFragment = `#${to.split('#').slice(1).join('#')}`;
    } else if (typeof to === 'object' && typeof to.hash === 'string') {
      hashFragment = to.hash;
    }

    try {
      if (onClick) onClick(e);
    } catch (ex) {
      e.preventDefault();
      throw ex;
    }

    if (
      hashFragment !== '' &&
      // ignore non-vanilla click events, same as react-router
      // below logic adapted from react-router: https://github.com/ReactTraining/react-router/blob/fc91700e08df8147bd2bb1be19a299cbb14dbcaa/packages/react-router-dom/modules/Link.js#L43-L48
      !e.defaultPrevented && // onClick prevented default
      e.button === 0 && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc
      !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) // ignore clicks with modifier keys
    ) {
      // console.log('Click to HashLink for', hashFragment);
      setScrollTo(hashFragment);
      // scrollFunction =
      //   scroll ||
      //   ((el) => (smooth ? el.scrollIntoView({ behavior: 'smooth' }) : el.scrollIntoView()));
      // hashLinkScroll(timeout);
    }
  };

  return (
    <NavLink activeClassName={activeClassName} className={className} to={to} onClick={handleClick}>
      {children}
    </NavLink>
  );
};

export default HashLink;
