import React from 'react';

const Contact: React.FC = () => {
  return (
    <form method="POST" action="/">
      <div className="pt-4 first:pt-0">
        <label htmlFor="first_name" className="block">
          Vorname
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="first_name"
            id="first_name"
            autoComplete="given-name"
            className="py-2 px-4 block w-full shadow-sm border-warm-gray-300"
          />
        </div>
      </div>
      <div className="pt-4 first:pt-0">
        <label htmlFor="last_name" className="block">
          Nachname
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="last_name"
            id="last_name"
            autoComplete="family-name"
            className="py-2 px-4 block w-full shadow-sm border-warm-gray-300"
          />
        </div>
      </div>
      <div className="pt-4 first:pt-0">
        <label htmlFor="email" className="block">
          Email
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            className="py-2 px-4 block w-full shadow-sm border-warm-gray-300"
          />
        </div>
      </div>
      <div className="pt-4 first:pt-0">
        <div className="flex justify-between items-end">
          <label htmlFor="phone" className="block">
            Telefon
          </label>
          <span id="phone-optional" className="text-sm">
            Optional
          </span>
        </div>
        <div className="mt-1">
          <input
            type="text"
            name="phone"
            id="phone"
            autoComplete="tel"
            className="py-2 px-4 block w-full shadow-sm border-warm-gray-300"
            aria-describedby="phone-optional"
          />
        </div>
      </div>
      <div className="pt-4 first:pt-0 sm:col-span-2">
        <label htmlFor="subject" className="block">
          Betreff
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="subject"
            id="subject"
            className="py-2 px-4 block w-full shadow-sm border-warm-gray-300"
          />
        </div>
      </div>
      <div className="pt-4 first:pt-0 sm:col-span-2">
        <div className="flex justify-between items-end">
          <label htmlFor="message" className="block">
            Nachricht
          </label>
          <span id="message-max" className="text-sm">
            Max. 500 Zeichen
          </span>
        </div>
        <div className="mt-1">
          <textarea
            id="message"
            name="message"
            rows={4}
            className="py-2 px-4 block w-full shadow-sm border-warm-gray-300"
            aria-describedby="message-max"
            defaultValue={''}
          />
        </div>
      </div>
      <div className="pt-4 first:pt-0 sm:col-span-2 sm:flex sm:justify-end">
        <button
          type="submit"
          className="mt-2 w-full inline-flex items-center justify-center px-6 py-2 border border-transparent shadow-sm text-base text-white bg-ci-blue hover:bg-ci-orange focus:outline-none focus:bg-ci-orange sm:w-auto"
        >
          Senden
        </button>
      </div>
    </form>
  );
};

export default Contact;
