import clsx from 'clsx';
import React from 'react';
import FormElement from 'components/elements/Form';
import Text from 'components/elements/Text';
import { findElement, findElements, HeadlineViewData } from 'lib/elements';

import { ContainerProps } from './ContainerProps';

const Form: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  // const { props } = tryParse(container.props);

  const headline = findElement<HeadlineViewData>(container, 'Headline');
  const texts = findElements(container, 'Text');
  const form = findElement(container, 'Form');

  return (
    <div
      className={clsx(
        'pt-6 sm:pt-14 lg:pt-28 first:pt-0 first:sm:pt-0 first:lg:pt-0 grid grid-cols-1 gap-10 gap-y-7 lg:grid-cols-2',
        className
      )}
      data-component="Form"
    >
      <div>
        {headline && (
          <h3
            className={clsx(
              'relative mb-3 text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-4xl whitespace-pre-line',
              { 'mt-8': headline.data?.subtitle }
            )}
          >
            {headline.data?.title}
            {headline.data?.subtitle && (
              <span className="absolute block -top-8 left-0 font-text text-3xs tracking-3widest uppercase">
                {'// '}
                {headline.data.subtitle}
              </span>
            )}
          </h3>
        )}
        {texts && texts.map((t) => <Text key={t.element.id} element={t.element} />)}
      </div>
      {form && <FormElement className="pt-6 lg:pt-0" element={form.element} />}
    </div>
  );
};

export default Form;
