import React from 'react';
import { CatalogComponent } from 'react-component-catalog';
import { SectionProvider } from 'contexts/SectionContext';
import { useContent } from 'hooks/useContent';
import Default from './Default';

import Content from './Content';
import Employees from './Employees';
import GoogleMap from './GoogleMap';
import Header from './Header';
import Hero from './Hero';
import Hidden from './Hidden';
import Products from './Products';

export const catalog = {
  Content,
  Employees,
  GoogleMap,
  Header,
  Hero,
  Hidden,
  Products,
};

const Sections: React.FC = () => {
  const { getSections } = useContent();
  // console.log('Sections', getSections());

  return (
    <>
      {getSections().map((section) => (
        <SectionProvider key={section.id} sectionId={section.id}>
          <CatalogComponent
            component={`sections.${section.type?.name}`}
            fallbackComponent={Default}
          />
        </SectionProvider>
      ))}
    </>
  );
};

export default Sections;
