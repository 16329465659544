import React, { createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';

interface Props {
  sectionId: string;
  children?: ReactNode;
}

interface State {
  sectionId: string;
}

const initialSectionState: State = {
  sectionId: '',
};

export const SectionContext = createContext<State>(initialSectionState);

export const SectionProvider = ({ sectionId, children }: Props): JSX.Element => {
  return <SectionContext.Provider value={{ sectionId }}>{children}</SectionContext.Provider>;
};

SectionProvider.propTypes = {
  children: PropTypes.node,
};

export default SectionContext;
