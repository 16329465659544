import clsx from 'clsx';
import React from 'react';
import Text from 'components/elements/Text';
import { findElements, HeadlineViewData } from 'lib/elements';

import { ContainerProps } from './ContainerProps';

const TextColumns: React.FC<ContainerProps> = ({ container, className }: ContainerProps) => {
  const columns = findElements(container, ['Headline', 'Text']);

  return (
    <div
      className={clsx('grid grid-cols-1 gap-10 gap-y-7 md:grid-cols-3', className)}
      data-component="TextColumns"
    >
      {columns &&
        columns.map((t) =>
          t.componentName === 'Headline' ? (
            <h4
              key={t.element.id}
              className="font-extrabold tracking-tight text-lg lg:text-xl lg:leading-9 whitespace-pre-line"
            >
              {t.data && (t.data as HeadlineViewData)?.title}
            </h4>
          ) : (
            <Text key={t.element.id} className="pt-0" element={t.element} />
          )
        )}
    </div>
  );
};

export default TextColumns;
