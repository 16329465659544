import kebabCase from 'lodash/kebabCase';
import trim from 'lodash/trim';
import { StringMap } from 'types';

/**
 * Safely encodes a path component and converts it to kebab case.
 * @remarks
 * Any preceding or trailing slashes will be trimmed.
 */
export function encodeComponent(comp: string): string {
  return comp ? encodeURIComponent(kebabCase(trim(comp, '/'))) : comp;
}

/**
 * Safely concatenates path components.
 * @remarks
 * Each empty, null or undefined argument will be ignored.
 * Preserves the preceding slash, all other duplice slashes will be deleted.
 * @param args Each argument represents a path component.
 */
export function combinePath(...args: string[]): string {
  return (
    '/' +
    args
      .filter((s) => s != null && s !== '')
      // Trim all slashes except for the first argument if it starts with a relative protocol //
      .map((s, i) => (i === 0 && s.startsWith('//') ? '//' + trim(s, '/') : trim(s, '/')))
      // Filter strings of '/' or '//' which are empty after trimming
      .filter((s) => s !== '')
      .join('/')
  );
}

/**
 * Safely concatenates path components.
 * @remarks
 * Each empty, null or undefined argument will be ignored.
 * Duplice slashes will be deleted.
 * @param args Each argument represents a path component.
 */
export function combineUrl(...args: string[]): string {
  const url = combinePath(...args);
  return url.indexOf('://') > 0 || url.indexOf('//') === 0
    ? url.slice(1) // Remove preceding slash from absolute url
    : url; // Return relateive url as it is
}

/**
 * Safely removes path components.
 * @remarks
 * Each empty, null or undefined argument will be ignored.
 * Preserves the preceding slash, all other duplice slashes will be deleted.
 * @param path The path to reduce.
 * @param reduceBy The number of path components to delete from the end.
 */
export function reducePath(path: string, reduceBy = 1): string {
  const components = path ? path.split('/') : [];
  const precedingSlash = path && path.startsWith('/');
  if (components.length > 0 && reduceBy > 0) {
    const result = components
      .slice(0, -Math.min(reduceBy, components.length - 1))
      .filter((s) => s !== '')
      .join('/');
    return precedingSlash ? '/' + result : result;
  }
  return path;
}

/**
 * Generates the path for the specified error name or error number.
 */
export function errorPath(error: string | number, baseUrl = '/'): string {
  return combinePath(baseUrl, 'error', encodeComponent(String(error)));
}

/**
 * Replaces all parameter keys in a path by values.
 * @param path The path to replace parameter keys by values.
 * @param keyValues The key value pairs.
 * @example
 *   pathValues('api/:version', [['version', 'v2']]) -> 'api/v2'
 */
export function pathValues(path: string, keyValues: StringMap): string {
  return path
    ? combinePath(
        ...path
          .split('/')
          .map((comp) =>
            comp.startsWith(':') && comp.length > 1 ? keyValues[comp.slice(1)] || comp : comp
          )
      )
    : path;
}
