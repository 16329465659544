import { useMemo, useState } from 'react';
import { ApiClient } from 'jsonapi-react';
import useAuth from 'hooks/useAuth';
import { store } from 'store';

interface ApiState {
  client: ApiClient;
  error?: string;
  isLoading: boolean;
  schema?: any;
}

/**
 * Initializes a new ApiClient with the endpoint specified or from global configuration.
 * @param url Optional api endpoint.
 * @remarks
 * The useClient from jsonapi-react is preferred, to reuse cached queries and
 * benefit from auto refetch after a type has been mutated. This hook is to
 * create a new api client only if necessary.
 */
function useApi(url?: string): ApiState {
  const [error] = useState<string | undefined>(undefined);
  const [isLoading] = useState<boolean>(false);
  const [schema] = useState<any>(store.schema);
  const { accessToken } = useAuth();

  const client = useMemo(() => {
    return new ApiClient({
      url: url || store.configuration.apiEndpoint,
      schema: schema || {},
      headers: accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : undefined,
      cacheTime: 10 * 60,
      staleTime: 5 * 60,
    });
  }, [accessToken, schema, url]);

  return {
    client,
    error,
    isLoading,
    schema,
  };
}

export default useApi;
