import React, { useContext, useMemo } from 'react';
import { useQuery } from 'jsonapi-react';

import Menu from 'components/Menu';
import { SiteContext } from 'contexts/SiteContext';
import useLocationChange from 'hooks/useLocationChange';
import { getMenuItemsViewData } from 'lib/menu';
import * as Types from 'types';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const QuickStartMenu: React.FC<Props> = ({ open, onClose }: Props): JSX.Element | null => {
  const { data: website, routes } = useContext(SiteContext);
  useLocationChange(() => {
    if (onClose) {
      onClose();
    }
  });

  const fetch = useQuery<Array<Types.Menu>>(
    website && [
      'menus',
      {
        filter: `and(equals(websiteId,'${website.id}'),equals(name,'QuickStartMenu'))`,
        include: ['items'],
      },
    ]
  );

  const menuItems = useMemo(
    () =>
      fetch.data && fetch.data.length > 0 && fetch.data[0].items
        ? getMenuItemsViewData(fetch.data[0].items, routes, undefined, 0, 0)
        : [],
    [fetch.data, routes]
  );

  if (!open || !menuItems) {
    return null;
  }

  return (
    <Menu
      classNames={['hidden lg:flex justigy-end space-x-4 lg:space-x-8']}
      activeClassNames={['text-ci-orange']}
      itemClassNames={[
        'block whitespace-nowrap overflow-ellipsis overflow-hidden hover:text-ci-orange',
      ]}
      items={menuItems}
    />
  );
};

export default QuickStartMenu;
