import { Route } from './Route';

/**
 * Generates link to a route that matches the specified page id.
 * @param routes collection.
 * @param pageId identifies a page.
 * @param anchor optional parameter to add an anchor to the resulting link.
 * @returns
 */
export function getRouteTo(routes: Route[], pageId: string, anchor?: string): string {
  const route = routes.find((r) => r.pageId === pageId);
  const linkTo = route?.path || '';
  return anchor && anchor !== '' ? `${linkTo}#${anchor?.toLowerCase()}` : linkTo;
}
