import clsx from 'clsx';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

interface Props {
  className?: string;
  markdown: string;
}

const Markdown: React.FC<Props> = ({ className, markdown }: Props) => {
  const components = {
    h1: 'h4',
    h2: 'p',
    h3: 'p',
    h4: 'p',
    h5: 'p',
    h6: 'p',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    p({ node, className, children, ...props }: any): any {
      return (
        <p className={clsx('whitespace-pre-line', className)} {...props}>
          {children}
        </p>
      );
    },
  } as any;

  return (
    <ReactMarkdown
      className={clsx('markdown', className)}
      components={components}
      remarkPlugins={[gfm]}
      /*transformLinkUri={() => '#'}*/
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default Markdown;
