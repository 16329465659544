import clsx from 'clsx';
import React from 'react';
import GoogleMapReact from 'google-map-react';
import { getElementViewData, MapViewData } from 'lib/elements';
import { store } from 'store';

import { ElementProps } from './ElementProps';
import { default as ultraLightGray } from './styles/ultraLightGray.json';

interface MarkerProps {
  lat: number;
  lng: number;
  text?: string;
}

const Marker: React.FC<MarkerProps> = ({ text }: MarkerProps) => {
  return (
    <div className="animate-pulse w-4 h-4 border-solid border-2 border-ci-orange bg-ci-blue rounded-full">
      <span className="hidden">{text}</span>
    </div>
  );
};

const Map: React.FC<ElementProps> = ({ element, className }: ElementProps) => {
  const viewData = getElementViewData<MapViewData>(element);

  // Parse map data
  const center =
    viewData.data && Array.isArray(viewData.data.center) && viewData.data.center.length > 1
      ? { lat: viewData.data.center[0], lng: viewData.data.center[1] }
      : { lat: 0, lng: 0 };
  const marker =
    viewData.data && Array.isArray(viewData.data.marker) && viewData.data.marker.length > 1
      ? { lat: viewData.data.marker[0], lng: viewData.data.marker[1] }
      : undefined;

  return (
    <div className={clsx('relative aspect-w-8 aspect-h-8', className)}>
      <div className="absolute inset-0">
        <GoogleMapReact
          center={center}
          zoom={viewData.data?.zoom || 11}
          bootstrapURLKeys={{ key: store.configuration.googleMapsKey }}
          options={{ disableDefaultUI: true, styles: ultraLightGray }}
        >
          {marker && (
            <Marker lat={marker?.lat} lng={marker.lng} text={viewData.data?.title || ''} />
          )}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default Map;
