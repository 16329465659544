import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

function usePrevious(value: Location): Location | undefined {
  const ref = useRef<Location>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Calls the specified action when the location has changed.
 * @param action to call when location has changed.
 */
function useLocationChange(action: (next: Location, prev?: Location) => void): void {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  useEffect(
    () => {
      action(location, prevLocation);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );
}

export default useLocationChange;
